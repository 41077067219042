import { defineStore } from 'pinia';

const useDataStore = defineStore('data', {
    state: () => data,
    getters: {},
    actions: {
        get(key) {
            let keys = key.split('.');

            let target = this;

            keys.forEach((item) => {
                if (target !== undefined) {
                    target = target[item] ?? false;
                }
            });

            if (target === undefined) {
                target = false;
            }

            return target;
        },
        set(path, value) {
            const keys = Array.isArray(path) ? path : path.split('.');
            let currentStep = this;

            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];

                if (!currentStep[key] && !currentStep.hasOwnProperty(key)) {
                    const nextKey = keys[i + 1];
                    const useArray = /^\+?(0|[1-9]\d*)$/.test(nextKey);
                    currentStep[key] = useArray ? [] : {};
                }

                currentStep = currentStep[key];
            }

            const finalStep = keys[keys.length - 1];
            currentStep[finalStep] = value;
        },
        delete(key) {
            let keys = key.split('.');
            let target = this;

            keys.forEach((item, index) => {
                if (target !== undefined) {
                    try {
                        if (index === keys.length - 1) {
                            delete target[item];
                        } else {
                            target = target[item];
                        }
                    } catch (e) {
                        target = !e;
                    }
                }
            });
        },
        refresh() {
            return this;
        },
    },
});

export { useDataStore };
